import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import axios from "axios";
import { isNewVersion } from "@/utils/updateVersion.js";
import SocketIO from "socket.io-client"

Vue.use(VueRouter);

const routes = [
  // 首页自动跳转
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "*",
    redirect: "/404",
  },
  // 错误页面
  {
    path: "/404",
    name: "404",
    meta: {
      title: "错误",
      hideHeader: true, //隐藏头: 真
    },
    component: () => import("../views/404.vue"),
  },
  // 登录
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "看板 - 登录",
      hideHeader: true, //隐藏头: 真
    },
    component: () => import("../views/Login.vue"),
  },
  // 微信自动发送
  // Wx_img
  {
    path: "/wximg",
    name: "Wx_img",
    component: () => import("../views/Wx_img.vue"),
    meta: {
      title: "微信自动发送信息",
    },
    beforeEnter(to, from, next) {
      next();
    },
  },
  {
    path: "/wximg2",
    name: "Wx_img2",
    component: () => import("../views/Wx_img2.vue"),
    meta: {
      title: "微信自动发送信息",
    },
    beforeEnter(to, from, next) {
      next();
    },
  },
  // 微信卖货图片生成
  // WxGoods
  {
    path: "/wxgoods",
    name: "WxGoods",
    component: () => import("../views/WxGoods.vue"),
    meta: {
      title: "微信卖货图片生成",
    },
    beforeEnter(to, from, next) {
      next();
    },
  },
  // 测试页面
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/test.vue"),
    meta: {
      title: "测试",
    },
    beforeEnter(to, from, next) {
      next();
    },
  },
  // 首页
  {
    path: "/index",
    name: "HomeIndex",
    component: () => import("../views/HomeIndex.vue"),
    meta: {
      title: "首页",
    },
  },
  // 中控台
  {
    path: "/ban",
    name: "HomeView",
    component: HomeView,
    meta: {
      title: "看板中控台 - 首页",
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
      ],
      requiredAuth: 3
    },
  },
  {
    path: "/kanban",
    name: "KanBan",
    component: () => import("../views/KanBan.vue"),
    meta: {
      title: "看板 - 详细",
      hideHeader: true, //隐藏头: 真
      requiredAuth: 3,
    },
  },
  // 图片库
  {
    path: "/imgku",
    name: "Imgku",
    meta: {
      title: "图片库",
      requiredAuth: 3,
    },
    component: () => import("../views/Imgku.vue"),
  },
  // 客服
  {
    path: "/fuwu",
    name: "Fuwu",
    meta: {
      title: "客服服务行为准则",
      hideHeader: true, //隐藏头: 真
      // requiredAuth: 3,
    },
    component: () => import("../views/fuwu.vue"),
  },
  // 抖音数据
  {
    path: "/douyindata",
    name: "DouYinData",
    meta: {
      title: "抖音数据",
      requiredAuth: 3,
    },
    component: () => import("../views/douyindata.vue"),
  },
  // 插件中心 Plugins
  {
    path: "/plugins",
    name: "Plugins",
    meta: {
      title: "插件中心",
      requiredAuth: 3,
    },
    component: () => import("../views/Plugins.vue"),
  },
  // 图片上传 upload
  {
    path: "/upload",
    name: "Upload",
    meta: {
      title: "图片上传",
      requiredAuth: 3,
    },
    component: () => import("../views/Upload.vue"),
  },
  // 备货单
  {
    path: "/beihuo",
    name: "Beihuo",
    meta: {
      title: "备货单",
      requiredAuth: 3,
    },
    component: () => import("../views/Beihuo.vue"),
  },
  // 衣服
  {
    path: "/clothes",
    component: () => import("../views/clothes/index.vue"),
    meta: {
      title: "衣服",
      requiredAuth: 2.1,
      hideHeader: true,
    },
    children: [
      {
        path: "", // 当路径为 /clothes 时重定向到 /clothes/summary
        redirect: "summary",
      },
      {
        path: "list", // 这将作为重新配置的 Clothes 路由
        name: "Clothes",
        component: () => import("../views/clothes/ClothesList.vue"),
        meta: {
          title: "衣服 - 商品",
          requiredAuth: 2.1,
          hideHeader: true,
        },
      },
      {
        path: "index",
        name: "Home",
        component: () => import("../views/clothes/Home.vue"),
        meta: {
          title: "衣服 - 首页",
          requiredAuth: 2.1,
          hideHeader: true,
        },
      },
      {
        path: "sales",
        name: "Sales",
        component: () => import("../views/clothes/Sales.vue"),
        meta: {
          title: "衣服 - 销售单",
          requiredAuth: 2.1,
          hideHeader: true,
        },
      },
      {
        path: "summary",
        name: "ClothesSummary",
        component: () => import("../views/clothes/Summary.vue"),
        meta: {
          title: "衣服 - 汇总单",
          requiredAuth: 2.1,
          hideHeader: true,
        },
      },
      {
        path: "salesOrderDetails/:id",
        name: "SalesOrderDetails",
        component: () => import("@/views/clothes/SalesOrderDetails.vue"),
        meta: {
          title: "衣服 - 销售单详细",
          hideHeader: true,
        },
      },
    ],
  },
  {
    path: "/bbimg",
    name: "bbImg",
    meta: {
      title: "图片库",
      hideHeader: true, //隐藏头: 真
    },
    component: () => import("../views/bbImg.vue"),
  },
  // 退出
  {
    path: "/exit",
    name: "exit",
    meta: {
      title: "退出",
      hideHeader: true, //隐藏头: 真
    },
    component: () => import("../views/exit.vue"),
  },
  // 咖啡
  {
    path: "/coffee",
    name: "Coffee",
    meta: {
      title: "看板 - 咖啡",
    },
    component: () => import("../views/Coffee.vue"),
  },
  // 生产单
  {
    path: "/production",
    component: () => import("../views/Production.vue"),
    meta: {
      title: "生产单",
      requiredAuth: 3,
    },
    children: [
      {
        path: "list",
        name: "List",
        component: () => import("../views/production/List.vue"),
        meta: {
          title: "生产单列表",
          requiredAuth: 3,
        },
      },
      {
        path: "summary",
        name: "Summary",
        component: () => import("../views/production/Summary.vue"),
        meta: {
          title: "生产单汇总",
          requiredAuth: 3,
        },
      },
      {
        path: "advancedsearch",
        name: "AdvancedSearch",
        component: () => import("../components/AdvancedSearch.vue"),
        meta: {
          title: "高级查询",
          requiredAuth: 3,
        },
      },
      {
        path: "notime",
        name: "Notime",
        component: () => import("../views/production/Notime.vue"),
        meta: {
          title: "生产单删除时间",
          requiredAuth: 3,
        },
      },
      {
        path: "fastscd",
        name: "Fastscd",
        component: () => import("../views/production/Fastscd.vue"),
        meta: {
          title: "生成生产单",
          requiredAuth: 3,
        },
      },
      {
        path: "scdusers",
        name: "ScdUsers",
        component: () => import("../views/production/ScdUsers.vue"),
        meta: {
          title: "用户管理",
          requiredAuth: 3,
        },
      },
      {
        path: "",
        redirect: "/production/list",
        requiredAuth: 3,
      },
    ],
  },
  // stock
  {
    path: "/stock",
    name: "stock",
    meta: {
      title: "stock",
    },
    component: () => import("../views/stock/index.vue"),
  },
  // 更多
  {
    path: "/more",
    // name: 'Production',
    component: () => import("../views/Production.vue"),
    meta: {
      title: "更多",
    },
    children: [
      {
        path: "textlu",
        name: "Lu",
        component: () => import("../views/more/Textlu.vue"),
        meta: {
          title: "经典语录",
          requiredAuth: 3,
        },
      },
      {
        path: "liststream",
        name: "LiveStreamPlan",
        component: () => import("../views/more/LiveStreamPlan.vue"),
        meta: {
          title: "直播计划",
          requiredAuth: 3,
        },
      },
      {
        path: "projectrequirements",
        name: "ProjectRequirements",
        component: () => import("../views/more/ProjectRequirements.vue"),
        meta: {
          title: "项目需求",
          requiredAuth: 3,
        },
      },
      {
        path: "projectrequirementslist",
        name: "ProjectRequirementsList",
        component: () => import("../views/more/ProjectRequirementsList.vue"),
        meta: {
          title: "需求列表",
          requiredAuth: 3,
        },
      },
      // AddUpdateLog
      {
        path: "updatelog",
        name: "Updatelog",
        component: () => import("../views/more/Updatelog.vue"),
        meta: {
          title: "更新日志",
          requiredAuth: 3,
        },
      },
      {
        path: "addupdatelog",
        name: "AddUpdateLog",
        component: () => import("../views/more/AddUpdateLog.vue"),
        meta: {
          title: "新建更新",
          requiredAuth: 3,
        },
      },
      {
        path: "inputcomparison",
        name: "InputComparison",
        component: () => import("../views/more/InputComparison.vue"),
        meta: {
          title: "查找重复列",
        },
      },
      {
        path: "about",
        name: "Aabout",
        component: () => import("../views/more/About.vue"),
        meta: {
          title: "关于本站",
        },
      },
      {
        path: "confession",
        name: "Confession",
        component: () => import("../views/more/Confession.vue"),
        meta: {
          title: "表白情书",
        },
      },
      // 快递组手备货单 beihuodan
      {
        path: "beihuodan",
        name: "Beihuodan",
        component: () => import("../views/more/Beihuodan.vue"),
        meta: {
          title: "仓库库存",
          meta: [
            {
              name: "viewport",
              content:
                "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
            },
          ],
          requiredAuth: 1,
        },
      },
      // 仓库 Kucun
      {
        path: "kucun",
        name: "Kucun",
        component: () => import("../views/more/Kucun.vue"),
        meta: {
          title: "仓库位置",
          meta: [
            {
              name: "viewport",
              content:
                "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
            },
          ],
          requiredAuth: 1,
        },
      },
      // 装换对比 duibi
      {
        path: "zhuanhuan",
        name: "Zhuanhuan",
        component: () => import("../views/more/Zhuanhuan.vue"),
        meta: {
          title: "退货转换",
          meta: [
            {
              name: "viewport",
              content:
                "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
            },
          ],
        },
      },
      {
        path: "",
        redirect: "/more/textlu",
      },
    ],
  },
  // 统计
  {
    // Countbag
    path: "/countbag",
    name: "Countbag",
    component: () => import("../views/Countbag.vue"),
    meta: {
      title: "统计 - 详细",
      requiredAuth: 3,
    },
  },
];

let url = `https://yckanban.jackafan.top/`;
if (process.env.NODE_ENV == "development") {
  url = `http://localhost:3000/`;
} else if (process.env.NODE_ENV == "production") {
  //生产环境
} else {
  //本地环境
}

const router = new VueRouter({
  routes,
});

let socket = null;

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.meta.requiresAuth && !token) {
    // 如果目标路由需要认证且用户未登录
    localStorage.setItem("url", to.fullPath); // 存储目标 URL
    next({ path: "/login" }); // 跳转到登录页面
  } else {
    next(); // 继续导航
  }
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  if (to.path === "/login" || to.path === "/fuwu" || to.path == "/test") {
    // 登录页 不需要判断
    next();
  } else {
    let token = localStorage.getItem("token");
    if (token) {
      // 登录成功，验证 token
      axios({
        method: "post",
        url: `${url}users/verify`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.code != 200) {
            // 验证失败，清除 token 并重定向到登录页
            localStorage.removeItem("token");
            router.push("login");
          } else {
            // 验证成功
            if (
              to.meta.requiredAuth &&
              res.data.guanli < to.meta.requiredAuth
            ) {
              // 如果用户的 guanli 值低于 requiredAuth，重定向到首页或者404页面
              next("/404");
            } else {
              // console.log(res.data.guanli);
              
              // 用户有权限，继续访问
              next();
            }
          }
        })
        .catch((err) => {
          // 验证请求失败，清除 token 并重定向到登录页
          localStorage.removeItem("token");
          router.push("login");
        });
    } else {
      // 没有 token，重定向到登录页
      router.push("login");
    }
  }
  if(socket == null){
    setTimeout(()=>{
      socket = SocketIO(url, {
        query: {
          token: 11,
          path:to.path
        }
      });
  
      socket.on('error', (error) => {
        // 阻止错误信息输出到控制台
        console.error(error.message);
      });
  
      // 将socket实例添加到Vue原型上
      Vue.prototype.$socket = socket;
  
      socket.io.opts.query.path = to.path;
    })
  }else{
  }
});

router.afterEach((to, from) => {
  isNewVersion();
});

export default router;
